var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-week-picker",
    {
      attrs: { format: "YYYY-MM-DD", "allow-clear": "" },
      on: { change: _vm.onChange },
    },
    [
      _c("span", { staticClass: "weekBox" }, [
        _vm._v(_vm._s(_vm.time ? _vm.time : "请选择周")),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }